


































































import { Vue, Component, Prop, ModelSync } from 'vue-property-decorator';
import { Question } from '../../models';
import { defaultQuestion, defaultColors, defaultIcons, defaultQuestionTypes } from '../../defaults';

@Component
export default class QuestionView extends Vue {
  @ModelSync('value', 'change', { default: defaultQuestion })
  readonly question!: Question;

  @Prop({ type: Number, default: 1 })
  readonly sequence!: number;

  @Prop({ type: Boolean, default: true })
  readonly showQuestion!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showSequence!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showInfo!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showChoices!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hideCorrect!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hideWrong!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly blurWrong!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly compareChoices!: boolean;

  @Prop({ default: () => [] })
  readonly chosen!: boolean[];

  @Prop({ default: defaultColors })
  readonly colors!: string[];

  @Prop({ default: defaultIcons })
  readonly icons!: string[];

  questionTypes = defaultQuestionTypes;
  checkedIcon = 'mdi-checkbox-marked-circle';
  checkedColor = 'success';
  uncheckedIcon = 'mdi-checkbox-blank-circle';
  uncheckedColor = 'default';
  wrongIcon = 'mdi-close-circle';
  wrongColor = 'error';

  get correctChoices() {
    return this.question.choices.filter((c) => c.correct);
  }

  choiceColor(cidx): string {
    return this.colors[cidx % this.colors.length];
  }
  choiceIcon(cidx): string {
    return this.icons[cidx % this.icons.length];
  }
}
