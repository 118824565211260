


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { Quiz, QuizTheme, Game } from '../../models';
import TrueFalseQuestionView from './TrueFalseQuestionView.vue';
import SingleChoiceQuestionView from './SingleChoiceQuestionView.vue';
import MultipleChoiceQuestionView from './MultipleChoiceQuestionView.vue';

@Component({
  components: {
    TrueFalseQuestionView,
    SingleChoiceQuestionView,
    MultipleChoiceQuestionView,
  },
})
export default class QuestionResults extends Vue {
  @Prop({ required: true })
  readonly quiz!: Quiz;

  @Prop({ required: true })
  readonly game!: Game;

  @Prop({ required: true })
  readonly theme!: QuizTheme;

  get questionId() {
    return this.game.currentQuestion ? this.game.currentQuestion - 1 : null;
  }
  get question() {
    return this.questionId !== null ? this.quiz.questions[this.questionId] : null;
  }
  get responses() {
    return this.question !== null ? Object.values(this.game.responses[this.question.id]) : [];
  }
  get choices() {
    return this.question ? this.question.choices : [];
  }
  get choiceTitles() {
    return this.choices.map((c) => c.title);
  }
  get choiceParticipants() {
    /*
    for (const response of this.responses) {
      console.log('response', response);
    }
    */
    return [1, 5, 7, 0];
  }
  getChoiceColor(options) {
    if (!this.question) return null;
    const choice = this.choices[options.dataPointIndex];
    console.log('Options', options, choice, this.choices);
    return choice && choice.correct ? '#4caf50' : '#f44336';
  }

  chartOptions = {
    chart: {
      type: 'bar',
      dropShadow: { enabled: true, top: 0, left: 0, blur: 1, opacity: 0.5 },
    },
    colors: [this.getChoiceColor],
    plotOptions: { bar: { borderRadius: 4, horizontal: true, barHeight: '85%' } },
    grid: {
      show: false,
    },
    dataLabels: { enabled: true },
    tooltip: { enabled: false },
    xaxis: {
      categories: this.choiceTitles,
      lines: { show: false },
      floating: false,
      axisTicks: { show: false },
      axisBorder: { show: false },
      labels: { show: false },
    },
    yaxis: {
      lines: { show: false },
      floating: false,
      axisTicks: { show: false },
      axisBorder: { show: false },
      labels: { show: true },
    },
  };
  chartSeries = [{ name: 'Participants', data: this.choiceParticipants }];
}
