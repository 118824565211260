var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.game)?_c('v-card',{staticClass:"d-flex align-center",attrs:{"width":"500"}},[_c('v-chip',{staticClass:"accent mx-1",attrs:{"label":"","dark":""},domProps:{"textContent":_vm._s(_vm.game.phase)}}),_c('v-spacer'),(_vm.game.settings.enableMusic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","icon":"","color":"accent"},on:{"click":function($event){return _vm.$bus.$emit('quiz-settings-change', 'enableMusic', false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-music")])],1)]}}],null,false,608915750)},[_c('span',{staticClass:"text-body-2"},[_vm._v("Disable music")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","icon":"","color":"accent"},on:{"click":function($event){return _vm.$bus.$emit('quiz-settings-change', 'enableMusic', true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-music-off")])],1)]}}],null,false,3948834671)},[_c('span',{staticClass:"text-body-2"},[_vm._v("Enable music")])]),(_vm.game.settings.enableSounds)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","icon":"","color":"accent"},on:{"click":function($event){return _vm.$bus.$emit('quiz-settings-change', 'enableSounds', false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-volume-high")])],1)]}}],null,false,2335287997)},[_c('span',{staticClass:"text-body-2"},[_vm._v("Disable sounds")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","icon":"","color":"accent"},on:{"click":function($event){return _vm.$bus.$emit('quiz-settings-change', 'enableSounds', true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-volume-off")])],1)]}}],null,false,246495703)},[_c('span',{staticClass:"text-body-2"},[_vm._v("Enable sounds")])]),(_vm.game.settings.enableTimer)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","icon":"","color":"accent"},on:{"click":function($event){return _vm.$bus.$emit('quiz-settings-change', 'enableTimer', false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-timer")])],1)]}}],null,false,1223684838)},[_c('span',{staticClass:"text-body-2"},[_vm._v("Disable timer")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","icon":"","color":"accent"},on:{"click":function($event){return _vm.$bus.$emit('quiz-settings-change', 'enableTimer', true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-timer-off")])],1)]}}],null,false,3070293999)},[_c('span',{staticClass:"text-body-2"},[_vm._v("Enable timer")])]),(_vm.game.settings.enableTimer)?_c('Timer',{attrs:{"value":_vm.time,"reverse":true,"autostart":_vm.game.phase === 'QuestionStart',"readonly":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var time = ref.time;
var timeout = ref.timeout;
return [_c('span',{staticClass:"text-h5",class:timeout() ? 'error--text' : 'accent--text'},[_vm._v(_vm._s(time))])]}}],null,false,571868855)}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"tile":"","icon":"","color":"primary","disabled":!_vm.game.currentQuestion || _vm.game.currentQuestion <= 1},on:{"click":function($event){return _vm.$bus.$emit('quiz-question-previous')}}},[_c('v-icon',[_vm._v("mdi-skip-previous")])],1),_c('v-btn',{staticClass:"px-1",attrs:{"tile":"","text":""}},[_vm._v("Question "+_vm._s(_vm.game.currentQuestion))]),_c('v-btn',{attrs:{"tile":"","icon":"","color":"primary","disabled":_vm.game.currentQuestion >= _vm.quiz.questions.length},on:{"click":function($event){return _vm.$bus.$emit('quiz-question-next')}}},[_c('v-icon',[_vm._v("mdi-skip-next")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"tile":"","icon":"","color":"error"},on:{"click":function($event){return _vm.$bus.$emit('quiz-question-next')}}},[_c('v-icon',[_vm._v("mdi-stop")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }