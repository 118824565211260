









































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Question, QuizTheme, Answers, UserStatistics } from '../../models';
import { defaultQuestionTypes } from '../../defaults';
import TrueFalseQuestionView from './TrueFalseQuestionView.vue';
import SingleChoiceQuestionView from './SingleChoiceQuestionView.vue';
import MultipleChoiceQuestionView from './MultipleChoiceQuestionView.vue';
import QuizScreen from '../quiz/QuizScreen.vue';
import ParticipantStatus from '../quiz/ParticipantStatus.vue';
import GameStatus from '../quiz/GameStatus.vue';

@Component({
  components: {
    QuizScreen,
    ParticipantStatus,
    GameStatus,
    TrueFalseQuestionView,
    SingleChoiceQuestionView,
    MultipleChoiceQuestionView,
  },
})
export default class QuestionEnd extends Vue {
  @Prop({ required: true })
  readonly value!: Question;

  @Prop({ required: true })
  readonly theme!: QuizTheme;

  @Prop({ default: null })
  readonly sequence!: Number;

  @Prop({ default: 0 })
  readonly totalQuestions!: Number;

  @Prop({ default: null })
  readonly chosen!: Answers | null;

  @Prop({ default: false })
  readonly lastQuestion!: Boolean;

  @Prop({ default: null })
  readonly stats!: UserStatistics;

  @Prop({ default: null })
  readonly rank!: Number;

  questionTypes = defaultQuestionTypes;
  remainingTime = this.questionTime;
  timer = this.questionTime;
  timerId: any = null;
  answers: Answers = [];

  get questionTime(): number {
    return (this.value.time || 10000) / 1000;
  }
  get timerValue(): number {
    return (this.remainingTime / this.timer) * 100;
  }

  get correctChoices() {
    return this.value.choices.filter((c) => c.correct);
  }
  get selectedChoices() {
    return this.answers.filter((a) => a === true);
  }
  get isComplete() {
    return this.selectedChoices.length === this.correctChoices.length;
  }
  get isCorrect() {
    const choices = this.value.choices.map((c) => c.correct);
    for (let i = 0; i < this.answers.length; i++) {
      if (this.answers[i] !== choices[i]) return false;
    }
    return true;
  }

  @Watch('value', { immediate: true })
  onValueChange(value) {
    //console.log('Question changed', value, this.chosen);
    this.answers = this.chosen && this.chosen.length > 0 ? this.chosen : new Array(value.choices.length).fill(false);
  }
}
