





























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Question as QuestionType, QuizTheme } from '../models';
import { defaultQuestionTypes } from '../defaults';
import TrueFalseQuestionView from '../components/question/TrueFalseQuestionView.vue';
import SingleChoiceQuestionView from '../components/question/SingleChoiceQuestionView.vue';
import MultipleChoiceQuestionView from '../components/question/MultipleChoiceQuestionView.vue';

@Component({
  components: {
    TrueFalseQuestionView,
    SingleChoiceQuestionView,
    MultipleChoiceQuestionView,
  },
})
export default class Question extends Vue {
  @Prop({ required: true })
  readonly value!: QuestionType;

  @Prop({ required: true })
  readonly theme!: QuizTheme;

  @Prop({ default: null })
  readonly sequence!: Number;

  @Prop({ default: false })
  readonly showTimer!: Boolean;

  questionTypes = defaultQuestionTypes;
  remainingTime = this.$props.value.timer || 10;
  timer = this.$props.value.timer || 10;
  timerId: any = null;
  answers: boolean[] = new Array(this.$props.value.choices.length).fill(false);

  get timerValue() {
    return (this.remainingTime / this.timer) * 100;
  }
  get timerColor() {
    let color = 'green';
    if (this.timerValue < 25) {
      color = 'red';
    } else if (this.timerValue < 50) {
      color = 'amber';
    } else {
      color = 'green';
    }
    return color;
  }

  get correctChoices() {
    const correctChoices = this.value.choices.filter((c) => c.correct);
    return correctChoices.length;
  }
  get answered() {
    return this.answers.filter((a) => a === true);
  }

  onChoiceClicked(cidx: number) {
    const newVal = !this.answers[cidx];
    this.$set(this.answers, cidx, newVal); // Reactively change the value
    this.$emit('choice-clicked', cidx, this.value, this.sequence, newVal); // Bubble up event to parent
    //console.log('Clicked', cidx, this.answers);
  }
  onQuestionClicked() {
    this.startTimer();
  }
  startTimer() {
    this.timer = this.remainingTime = this.$props.value.timer || 10;
    if (this.timerId) this.stopTimer();
    this.timerId = setInterval(this.tick, 1000);
    //console.log('Starting timer', this.timerId);
  }
  stopTimer() {
    clearInterval(this.timerId);
    //console.log('Stopping timer', this.timerId);
    this.timerId = null;
  }
  tick() {
    this.remainingTime--;
    //console.log('Timer tick', this.timer, this.remainingTime);
    if (this.remainingTime === 0) this.stopTimer();
  }

  beforeDestroy() {
    //console.log('Cleaning up timer on destroy');
    clearInterval(this.timerId);
  }
}
