








































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { defaultQuizCountdown } from '../../defaults';
import { Quiz, Game, User } from '../../models';
import Timer from '../Timer.vue';

@Component({ components: { Timer } })
export default class GameToolbar extends Vue {
  @Prop({ required: true })
  readonly quiz!: Quiz;

  @Prop({ required: true })
  readonly game!: Game;

  time: number | null = null;

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get isOwner(): boolean {
    return this.game ? this.userId === this.game.owner : false;
  }

  @Watch('game.currentQuestion', { immediate: true })
  onQuestionChange(sequence) {
    if (!sequence) return;
    const question = this.quiz.questions[sequence - 1];
    const time = question.time ? question.time / 1000 + defaultQuizCountdown : null; // convert from milliseconds to seconds
    //console.log('Question changed', time);
    this.$set(this, 'time', null); // Reset the timer to trigger change detection!
    this.$nextTick(() => this.$set(this, 'time', time));
  }
}
