




























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Question, QuizTheme, Answers, UserStatistics } from '../../models';
import { defaultQuestionTypes } from '../../defaults';
import TrueFalseQuestionView from './TrueFalseQuestionView.vue';
import SingleChoiceQuestionView from './SingleChoiceQuestionView.vue';
import MultipleChoiceQuestionView from './MultipleChoiceQuestionView.vue';
import QuestionCountdown from './QuestionCountdown.vue';
import QuizScreen from '../quiz/QuizScreen.vue';
import ParticipantStatus from '../quiz/ParticipantStatus.vue';
import GameStatus from '../quiz/GameStatus.vue';

@Component({
  components: {
    QuizScreen,
    ParticipantStatus,
    GameStatus,
    QuestionCountdown,
    TrueFalseQuestionView,
    SingleChoiceQuestionView,
    MultipleChoiceQuestionView,
  },
})
export default class QuestionStart extends Vue {
  @Prop({ required: true })
  readonly value!: Question;

  @Prop({ required: true })
  readonly theme!: QuizTheme;

  @Prop({ default: null })
  readonly sequence!: Number;

  @Prop({ default: 0 })
  readonly totalQuestions!: Number;

  @Prop({ default: null })
  readonly chosen!: Answers | null;

  @Prop({ default: false })
  readonly lastQuestion!: Boolean;

  @Prop({ default: null })
  readonly stats!: UserStatistics;

  @Prop({ default: null })
  readonly rank!: Number;

  questionTypes = defaultQuestionTypes;
  remainingTime = this.questionTime;
  startTime = 0;
  endTime = 0;
  timer = this.questionTime;
  timerId: any = null;
  showCountdown: boolean | null = null;
  answers: Answers = [];

  get questionTime(): number {
    return (this.value.time || 10000) / 1000;
  }
  get timerValue(): number {
    return (this.remainingTime / this.timer) * 100;
  }

  get correctChoices() {
    return this.value.choices.filter((c) => c.correct);
  }
  get selectedChoices() {
    return this.answers.filter((a) => a === true);
  }
  get isComplete() {
    return this.selectedChoices.length === this.correctChoices.length;
  }
  get isCorrect() {
    const choices = this.value.choices.map((c) => c.correct);
    for (let i = 0; i < this.answers.length; i++) {
      if (this.answers[i] !== choices[i]) return false;
    }
    //console.log('Correct!');
    return true;
  }
  get isSubmitted() {
    return this.endTime > 0;
  }

  @Watch('value', { immediate: true })
  onValueChange(value) {
    //console.log('Question changed', value, this.chosen);
    //this.stopTimer();
    this.answers = this.chosen && this.chosen.length > 0 ? this.chosen : new Array(value.choices.length).fill(false);
    this.startCountdown();
  }

  onChoiceClicked(cidx: number) {
    if (this.remainingTime === 0 || !this.timerId) return; // Prevent changes if timeout reached
    const oldValue = this.answers[cidx];
    const newValue = !oldValue;
    this.$set(this.answers, cidx, newValue); // Reactively change the value
    this.$emit('choice-clicked', cidx, this.value, this.sequence); // Bubble up event to parent
    //console.log('Clicked', cidx, this.answers);
    if (this.correctChoices.length === 1) {
      this.stopTimer();
      this.submit();
    }
  }
  startCountdown() {
    this.showCountdown = true;
  }
  finishCountdown() {
    this.showCountdown = false;
    this.startTime = Date.now();
    this.startTimer();
  }
  startTimer() {
    this.timer = this.remainingTime = this.questionTime;
    this.stopTimer();
    this.timerId = setInterval(this.tick, 1000);
    //console.log('Starting timer', this.timerId);
  }
  stopTimer() {
    if (!this.timerId) return;
    clearInterval(this.timerId);
    //console.log('Stopping timer', this.timerId);
    this.timerId = null;
  }
  tick() {
    this.remainingTime--;
    //console.log('Timer tick', this.timer, this.remainingTime);
    if (this.remainingTime === 0) {
      // Timeout!
      this.stopTimer();
      //console.log('Timeout');
      //this.submit();
      this.timeout();
    }
  }
  submit() {
    this.endTime = Date.now();
    this.$emit('submit', this.answers, this.startTime, this.endTime, this.remainingTime);
  }
  timeout() {
    this.endTime = Date.now();
    this.$emit('timeout', this.answers, this.startTime, this.endTime, this.remainingTime);
  }

  beforeDestroy() {
    //console.log('Cleaning up timer on destroy');
    clearInterval(this.timerId);
  }
}
