
import { Component } from 'vue-property-decorator';
import { defaultChoices } from '../../defaults';
import { Choice } from '../../models';
import QuestionEdit from './QuestionEdit.vue';

@Component
export default class SingleChoiceQuestionEdit extends QuestionEdit {
  choices = defaultChoices(4);
  maxChoices = 6;

  created() {
    if (this.question.choices.length === 0) {
      this.question.choices = this.choices;
    }
  }

  toggleChoice(choice: Choice) {
    this.question.choices.forEach((c) => (c.correct = false));
    choice.correct = true; // Only one correct choice allowed!
  }
}
