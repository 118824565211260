import { firestore } from 'firebase/app';
import { db } from '@/firebase';
import { getId } from '@/utils';
import { Game, Question, Quiz, User, Participant } from './models';

export const quizService = {
  // References
  getQuizzesRef() {
    return db.collection('quizzes');
  },
  getQuizRef(quizId: string) {
    return this.getQuizzesRef().doc(quizId);
  },
  getGamesRef(quizId: string) {
    return this.getQuizRef(quizId).collection('games');
  },
  getGameRef(quizId: string, gameId: string) {
    return this.getGamesRef(quizId).doc(gameId);
  },
  getParticipantsRef(quizId: string, gameId: string) {
    return this.getGameRef(quizId, gameId).collection('participants');
  },
  getParticipantRef(quizId: string, gameId: string, participantId: string) {
    return this.getParticipantsRef(quizId, gameId).doc(participantId);
  },
  getConfigRef() {
    return db.collection('admin').doc('quizzes');
  },

  // Quizzes
  getAllQuizzes() {
    return this.getQuizzesRef();
  },
  getOwnQuizzes(userId: string) {
    return this.getQuizzesRef().where('owner', '==', userId);
  },
  getDraftQuizzes(userId: string) {
    return this.getOwnQuizzes(userId).where('status', '==', 'Draft');
  },
  getActiveQuizzes(userId: string) {
    return this.getOwnQuizzes(userId).where('status', '==', 'Active');
  },
  getClosedQuizzes(userId: string) {
    return this.getOwnQuizzes(userId).where('status', '==', 'Closed');
  },
  async createQuiz(quiz: Quiz) {
    await this.getQuizRef(quiz.id!).set(quiz, { merge: true });
  },
  async updateQuiz(quizId: string, quizData: firestore.UpdateData) {
    await this.getQuizRef(quizId).update(quizData);
  },
  async deleteQuiz(quizId: string) {
    await this.deleteAllGames(quizId);
    await this.getQuizRef(quizId).delete();
  },
  async cloneQuiz(quiz: Quiz, user: User) {
    const newQuiz: Quiz = { ...quiz };
    newQuiz.id = getId();
    newQuiz.status = 'Draft';
    newQuiz.owner = user.id;
    newQuiz.createdOn = Date.now();
    newQuiz.createdBy = user;
    newQuiz.updatedOn = null;
    newQuiz.updatedBy = null;
    //newQuiz.events = [];
    await this.createQuiz(newQuiz);
  },

  async addQuestion(quiz: Quiz, question: Question) {
    if (!quiz.questions) quiz.questions = [];
    quiz.questions.push(question);
  },
  async addQuizVideo(quizId: string, video: string) {
    await this.getQuizRef(quizId).update({ video: video });
  },
  async addQuestionVideo(question: Question, video: string) {
    question.video = video;
  },
  async addQuestionImage(question: Question, image: string) {
    question.image = image;
  },
  async addFavoriteQuiz(quizId: string) {
    //await this.updateQuiz(quizId, { ['stats.games']: firestore.FieldValue.increment(1) });
  },

  // Games
  getAllGames(quizId: string) {
    return this.getGamesRef(quizId);
  },
  async createGame(quizId: string, game: Game) {
    await this.getGameRef(quizId, game.id!).set(game, { merge: true });
    await this.updateQuiz(quizId, { ['stats.games']: firestore.FieldValue.increment(1) });
  },
  async updateGame(quizId: string, gameId: string, gameData: firestore.UpdateData) {
    await this.getGameRef(quizId, gameId).update(gameData);
  },
  async deleteGame(quizId: string, gameId: string) {
    await this.deleteAllParticipants(quizId, gameId);
    await this.getGameRef(quizId, gameId).delete();
  },
  async deleteAllGames(quizId: string) {
    const snapshot = await this.getGamesRef(quizId).get();
    return snapshot.docs.map(async (doc) => {
      const game = doc.data() as Game;
      await this.deleteGame(quizId, game.id);
    });
  },

  async createParticipant(quizId: string, gameId: string, participant: Participant) {
    //await this.getParticipantRef(quizId, gameId, participant.id).set(participant, { merge: true });
    await this.updateGame(quizId, gameId, { [`participants.${participant.id}`]: participant });
  },
  async updateParticipant(quizId: string, gameId: string, participant: Participant) {
    //await this.getParticipantRef(quizId, gameId, participant.id).update({ participant });
    await this.updateGame(quizId, gameId, { [`participants.${participant.id}`]: participant });
  },
  async deleteParticipant(quizId: string, gameId: string, participantId: string) {
    //await this.getParticipantRef(quizId, gameId, participantId).delete();
    await this.updateGame(quizId, gameId, { [`participants.${participantId}`]: firestore.FieldValue.delete() });
  },
  async deleteAllParticipants(quizId: string, gameId: string) {
    const snapshot = await this.getParticipantsRef(quizId, gameId).get();
    return snapshot.docs.map(async (doc) => {
      const participant = doc.data();
      await this.deleteParticipant(quizId, gameId, participant.id);
    });
  },
};

export default { quizService };
