
import { Component } from 'vue-property-decorator';
import { defaultChoices } from '../../defaults';
import { Choice } from '../../models';
import QuestionEdit from './QuestionEdit.vue';

@Component
export default class MultipleChoiceQuestionEdit extends QuestionEdit {
  choices = defaultChoices(4);
  maxChoices = 6;

  created() {
    if (this.question.choices.length === 0) {
      this.question.choices = this.choices;
    }
  }

  toggleChoice(choice: Choice) {
    choice.correct = choice.correct ? false : true; // Multiple correct choices allowed!
  }
}
