





















































































































































































































































































































































































































import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { func } from '@/firebase';
import { getStatusColor } from '@/utils';
import { Quiz, Question, QuestionType, User } from '../../models';
import { defaultQuestionTypes, defaultQuiz, defaultQuestion, QuestionTimerOptions } from '../../defaults';
import { importKahootQuiz, importQuizizzQuiz } from '../../utils';
import { quizService } from '../../services';

import ThemeSelector from '../ThemeSelector.vue';
import ImportDialog from '../ImportDialog.vue';
import TrueFalseQuestionEdit from '../question/TrueFalseQuestionEdit.vue';
import SingleChoiceQuestionEdit from '../question/SingleChoiceQuestionEdit.vue';
import MultipleChoiceQuestionEdit from '../question/MultipleChoiceQuestionEdit.vue';

@Component({
  components: {
    ThemeSelector,
    ImportDialog,
    TrueFalseQuestionEdit,
    SingleChoiceQuestionEdit,
    MultipleChoiceQuestionEdit,
  },
})
export default class QuizBuilder extends Vue {
  @PropSync('value', { type: Object as () => Quiz, required: true })
  quiz!: Quiz;

  quizAdmin = {};
  valid = false;
  step = 1;
  questionType: QuestionType | null = null;
  questionTypes = defaultQuestionTypes;
  questionTimerOptions = QuestionTimerOptions;
  questionDbDialog = false;
  kahootImportDialog = false;
  quizizzImportDialog = false;
  quizId = null;
  importingQuiz = false;
  selectedCategories: string[] = [];
  questions: Question[] = [];
  selectedQuestion: Question | null = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get statusColor(): string {
    return getStatusColor(this.quiz.status);
  }

  @Watch('quizAdmin')
  onQuizAdminChanged(quizAdmin) {
    if (quizAdmin && quizAdmin.questions && quizAdmin.questions.length > 0) {
      this.questions = quizAdmin.questions;
    }
  }

  created() {
    this.$bus.$emit('title-change', 'Quiz', '/quiz');
    this.$bind('quizAdmin', quizService.getConfigRef());
  }

  createQuestion(questionType) {
    const question: Question = defaultQuestion({ title: 'Question', type: questionType });
    this.quiz.questions.push(question);
    this.$nextTick(() => {
      this.questionType = null;
    });
  }
  deleteQuestion(index) {
    if (this.quiz.questions.length > index) {
      this.quiz.questions.splice(index, 1);
      this.$bus.$emit('snackbar-notify', 'Question successfully deleted', 'success');
    }
  }
  resetQuiz() {
    this.quiz = defaultQuiz(this.user);
    this.$emit('change', this.quiz);
    this.$bus.$emit('snackbar-notify', 'Quiz reset', 'success');
  }
  saveQuiz() {
    this.$emit('save', this.quiz);
  }
  deleteQuiz() {
    this.$emit('delete', this.quiz);
  }
  setQuizStatus(status: string) {
    this.quiz.status = status;
    this.saveQuiz();
  }
  changeType(question) {
    console.log('Type changed', question.type);
  }
  changeCategory(categories) {
    if (categories.length > 0) {
      this.questions = this.questions.filter((q: Question) => q && q.category && categories.includes(q.category.title));
    } else if (this.quizAdmin) {
      this.questions = this.quizAdmin['questions'];
    }
  }
  addQuestion() {
    if (this.selectedQuestion) {
      this.quiz.questions.push(this.selectedQuestion);
      this.questions = this.questions.filter((q: Question) => q.title !== this.selectedQuestion!['title']);
      this.selectedQuestion = null;
    }
    //this.questionDbDialog = false;
  }

  async importKahootQuiz(quizId: string) {
    if (!quizId) return;
    this.importingQuiz = true;
    const fetchQuizFromKahoot = func.httpsCallable('fetchQuizFromKahoot');
    const response = await fetchQuizFromKahoot(quizId);
    if (response) {
      const quizData = response.data;
      //console.log('Kahoot import', this.kahootQuizId, quizData);
      if (quizData.error) {
        this.$bus.$emit('snackbar-notify', `Kahoot quiz could not be imported (${quizData.error})`, 'error');
      } else {
        try {
          importKahootQuiz(this.quiz, quizData);
          this.$bus.$emit('snackbar-notify', 'Kahoot quiz successfully imported', 'success');
        } catch (error) {
          this.$bus.$emit('snackbar-notify', `Kahoot quiz could not be imported (${error})`, 'error');
        }
        //console.log('Quiz', this.quiz);
        this.$set(this, 'kahootImportDialog', false);
      }
    }
    this.importingQuiz = false;
    this.quizId = null;
  }

  async importQuizizzQuiz(quizId: string) {
    if (!quizId) return;
    this.importingQuiz = true;
    const fetchQuizFromQuizizz = func.httpsCallable('fetchQuizFromQuizizz');
    const response = await fetchQuizFromQuizizz(quizId);
    if (response) {
      const quizData = response.data;
      //console.log('Quizizz import', this.quizizzQuizId, quizData);
      if (quizData.error) {
        this.$bus.$emit('snackbar-notify', `Quizizz quiz could not be imported (${quizData.error})`, 'error');
      } else {
        try {
          importQuizizzQuiz(this.quiz, quizData);
          this.$bus.$emit('snackbar-notify', 'Quizizz quiz successfully imported', 'success');
        } catch (error) {
          this.$bus.$emit('snackbar-notify', `Quizizz quiz could not be imported (${error})`, 'error');
        }
        //console.log('Quiz', this.quiz);
        this.$set(this, 'quizizzImportDialog', false);
      }
    }
    this.importingQuiz = false;
    this.quizId = null;
  }
}
