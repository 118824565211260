










































































































































































import { Vue, Component, Prop, ModelSync } from 'vue-property-decorator';
import { Question, Choice, Categories } from '../../models';
import {
  defaultQuestion,
  defaultQuestionTypes,
  defaultChoices,
  QuestionTimerOptions,
  QuestionPointsOptions,
} from '../../defaults';

Component.registerHooks(['beforeRouteEnter']);

@Component
export default class QuestionEdit extends Vue {
  @ModelSync('value', 'change', { default: defaultQuestion })
  readonly question!: Question;

  @Prop({ default: 1 })
  readonly sequence!: Number;

  @Prop({ default: () => ['Default'] })
  readonly categories!: Categories;

  questionTypes = defaultQuestionTypes;
  timerOptions = QuestionTimerOptions;
  pointsOptions = QuestionPointsOptions;
  choices = defaultChoices(4);
  maxChoices = 6;
  showQuestionImage = false;
  showChoiceImage: boolean[] = [];

  get choiceCount() {
    return this.question.choices.length;
  }
  get choiceLimitReached() {
    return this.choiceCount >= this.maxChoices;
  }

  created() {
    if (this.question.choices.length === 0 || this.question.choices.length > this.maxChoices) {
      this.question.choices = this.choices;
    }
    this.showChoiceImage = new Array(this.question.choices.length).fill(false);
  }

  toggleQuestionImage() {
    this.showQuestionImage != this.showQuestionImage;
  }
  toggleChoiceImage(cidx: number, choice: Choice) {
    const toggle = this.showChoiceImage[cidx] ? false : true;
    this.$set(this.showChoiceImage, cidx, toggle);
  }
  toggleChoice(choice: Choice) {
    console.warn('[QuestionEdit.toggleChoice]', 'This method should be used from inheriging components only!');
    //choice.correct = choice.correct ? false : true;
  }
  addChoice() {
    const sequence = this.choiceCount + 1;
    if (sequence > this.maxChoices) return;
    const choice: Choice = { sequence, title: `Answer choice ${sequence}`, image: null, video: null, correct: false };
    this.question.choices.push(choice);
  }
  deleteChoice(cidx: number) {
    this.question.choices.splice(cidx, 1);
  }
}
