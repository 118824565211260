































































import { Vue, Component } from 'vue-property-decorator';
import { Quizzes, Quiz, User } from '../../models';
import { quizService } from '../../services';
import QuizTile from './QuizTile.vue';

@Component({ components: { QuizTile } })
export default class QuizzesList extends Vue {
  draftQuizzes: Quizzes = [];
  activeQuizzes: Quizzes = [];
  closedQuizzes: Quizzes = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  created() {
    this.$bus.$emit('title-change', 'Quiz');

    this.$bind('draftQuizzes', quizService.getDraftQuizzes(this.userId).orderBy('createdOn', 'desc'));
    this.$bind('activeQuizzes', quizService.getActiveQuizzes(this.userId).orderBy('createdOn', 'desc'));
    this.$bind('closedQuizzes', quizService.getClosedQuizzes(this.userId).orderBy('createdOn', 'desc'));

    // Fix issues with multiple listeners!
    this.$bus.$off('quiz-clone').$on('quiz-clone', this.cloneQuiz);
    this.$bus.$off('quiz-delete').$on('quiz-delete', this.deleteQuiz);
  }

  selectQuiz(quiz: Quiz) {
    this.$router.push(`/quiz/${quiz.id}`);
  }
  async cloneQuiz(quiz: Quiz) {
    try {
      await quizService.cloneQuiz(quiz, this.user);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully cloned', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async deleteQuiz(quiz: Quiz) {
    try {
      await quizService.deleteQuiz(quiz.id);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully deleted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
