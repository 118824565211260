































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Quiz as QuizType, Question as QuestionType } from '../models';
import Question from './Question.vue';

@Component({ components: { Question } })
export default class Quiz extends Vue {
  @Prop({ required: true })
  readonly quiz!: QuizType;

  points = 0;
  //score = new Array(this.quiz.questions.length) as QuestionScores
  score = new Array(this.quiz.questions.length).fill(0);

  get totalPoints(): number {
    let points = 0;
    //this.score.forEach((s) => (points += s));
    //console.log(points);
    //return points;
    return this.score.reduce((p, v) => p + v, 0);
  }

  onChoiceClicked(choiceIndex: number, question: QuestionType, sequence: number, value: boolean) {
    const choice = question.choices[choiceIndex];
    const questionIndex = sequence - 1;
    //console.log('Question choice clicked', choiceIndex, question, sequence, value, choice);
    if (choice.correct && value) {
      // Clicked a correct choice
      if (this.score[questionIndex] === 0) {
        const points = question.points || 0;
        this.$set(this.score, questionIndex, points);
        //this.points += points;
      }
    } else {
      this.$set(this.score, questionIndex, 0);
    }
    // TODO: Add time bonus scoring component!
    // TODO: Add streak bonus scoring component!
    // TODO: Add scoring logic for cases with multiple correct answers!
    // TODO: Add logic for showing correctly selected answers (dim the others!)
    // TODO: Add logic for highlighting wrongly selected answers!
  }
}
