
import { Component, Prop } from 'vue-property-decorator';
import QuestionView from './QuestionView.vue';

const defaultColors = () => ['cyan', 'pink'];

@Component
export default class TrueFalseQuestionView extends QuestionView {
  @Prop({ default: defaultColors })
  readonly colors!: string[];
}
