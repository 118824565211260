













import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Quiz, User } from '../models';
import { quizService } from '../services';
import QuizBuilder from '../components/quiz/QuizBuilder.vue';
import { defaultQuizStats } from '../defaults';

@Component({ components: { QuizBuilder } })
export default class QuizEditView extends Vue {
  @Prop({ required: true })
  readonly id!: string;

  quiz: Quiz | null = null;
  isOwner: boolean | null = null;

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  @Watch('id', { immediate: true })
  onIdChanged(id: string) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('quiz', quizService.getQuizRef(id));
  }

  @Watch('quiz')
  onQuizChanged(quiz: Quiz) {
    if (quiz) {
      document.title = quiz.title;
      this.isOwner = quiz.owner === this.userId;
      this.$bus.$emit('loading-indicator', false);
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Quiz', '/quiz');
  }

  async saveQuiz(quiz: Quiz) {
    try {
      quiz.updatedBy = this.user;
      quiz.updatedOn = Date.now();
      if (!quiz.stats) quiz.stats = defaultQuizStats;
      await quizService.updateQuiz(quiz.id, quiz);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully saved', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async deleteQuiz(quiz: Quiz) {
    try {
      await quizService.deleteQuiz(quiz.id);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully deleted', 'success');
      this.$router.push({ name: 'quiz-dashboard' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
