




































import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatPercent, formatValue } from '@/utils';
import { UserStatistics } from '../../models';

@Component
export default class ParticipantStatus extends Vue {
  @Prop({ default: null })
  readonly sequence!: Number;

  @Prop({ default: null })
  readonly totalQuestions!: Number;

  @Prop({ default: null })
  readonly stats!: UserStatistics;

  @Prop({ default: null })
  readonly rank!: Number;

  formatPercent = formatPercent;
  formatValue = formatValue;
}
