





































import { Vue, Component, Prop, Watch, ModelSync } from 'vue-property-decorator';

@Component
export default class ImportDialog extends Vue {
  @ModelSync('value', 'input', { default: null })
  quizId!: String | null;

  @Prop({ default: 'Import Quiz' })
  dialogTitle!: String;

  @Prop({ default: 'Quiz Import' })
  buttonTitle!: String;

  @Prop({ default: 'mdi-database-import' })
  icon!: String;

  @Prop({ default: 600 })
  width!: Number;

  @Prop({ default: false })
  importing!: Boolean;

  @Watch('importing')
  onChange(value) {
    if (!value) this.showDialog = false; // Close dialog when import is finished!
  }

  showDialog = false;
  //quizId: string | null = null;

  importQuiz() {
    this.$emit('quiz-import', this.quizId);
    //this.quizId = null;
  }
  closeDialog() {
    this.quizId = null;
    this.showDialog = false;
  }
}
