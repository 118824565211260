



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { defaultColors } from '../../defaults';

@Component
export default class QuizScreen extends Vue {
  @Prop({ default: 'transparent' })
  color!: String;

  @Prop({ default: null })
  image!: String;

  @Prop({ default: false })
  rotateColors!: Boolean;

  currentColor = this.color;
  colors = defaultColors();
  timerId: number | undefined = undefined;

  created() {
    if (this.rotateColors) {
      this.timerId = setInterval(this.swapColor, 5000);
    }
  }

  swapColor() {
    const colorIndex = Math.floor(Math.random() * this.colors.length);
    this.currentColor = this.colors[colorIndex];
  }

  beforeDestroy() {
    clearInterval(this.timerId);
    this.timerId = undefined;
  }
}
