
























import { Vue, Component, Prop } from 'vue-property-decorator';
import { QuizTheme } from '../../models';
import { defaultColors, defaultQuizCountdown } from '../../defaults';

@Component
export default class QuestionCountdown extends Vue {
  @Prop({ required: true })
  readonly theme!: QuizTheme;

  color = 'blue';
  countdown = defaultQuizCountdown;
  counter = defaultQuizCountdown;
  timerId: number | undefined = undefined;

  created() {
    this.timerId = setInterval(this.tick, 1000);
  }

  beforeDestroy() {
    //console.log('Clear timer', this.timerId);
    clearInterval(this.timerId);
    this.timerId = undefined;
  }

  tick() {
    this.counter--;
    if (this.counter === 0) {
      clearInterval(this.timerId);
      this.$emit('timeout');
    }
  }
}
