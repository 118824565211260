




































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Quiz, Games, Game } from '../../models';
import { formatDate } from '@/utils';
import { quizService } from '../../services';

@Component
export default class GamesList extends Vue {
  @Prop({ required: true })
  readonly quiz!: Quiz;

  @Prop({ required: true })
  readonly games!: Games;

  formatDate = formatDate;
  headers = [
    { text: 'Game', value: 'title' },
    { text: 'Status', value: 'status' },
    { text: 'Phase', value: 'phase' },
    { text: 'Question #', value: 'currentQuestion' },
    { text: 'Created On', value: 'createdOn' },
    { text: 'Responses', value: 'responses' },
    { text: 'Actions', value: null, width: '5%' },
  ];

  async deleteGame(game: Game) {
    try {
      await quizService.deleteGame(this.quiz.id, game.id);
      this.$bus.$emit('snackbar-notify', 'Game successfully deleted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
