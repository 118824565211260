




import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Quiz as QuizType } from '../models';
import { quizService } from '../services';
import Quiz from '../components/Quiz.vue';

Component.registerHooks(['beforeRouteEnter']);

@Component({ components: { Quiz } })
export default class QuizPreviewView extends Vue {
  @Prop({ required: true })
  readonly id!: string;

  quiz: QuizType | null = null;

  @Watch('id', { immediate: true })
  onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    try {
      this.$bind('quiz', quizService.getQuizRef(id));
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  @Watch('quiz')
  onQuizChange(quiz: QuizType) {
    if (quiz) {
      document.title = quiz.title;
      this.$bus.$emit('loading-indicator', false);
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Quiz', '/quiz');
  }
}
