



























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Quiz } from '../../models';
import { defaultQuestionTypes } from '../../defaults';
import TrueFalseQuestionView from './TrueFalseQuestionView.vue';
import SingleChoiceQuestionView from './SingleChoiceQuestionView.vue';
import MultipleChoiceQuestionView from './MultipleChoiceQuestionView.vue';

@Component({
  components: {
    TrueFalseQuestionView,
    SingleChoiceQuestionView,
    MultipleChoiceQuestionView,
  },
})
export default class QuestionsList extends Vue {
  @Prop({ required: true })
  readonly quiz!: Quiz;

  @Prop({ default: true })
  readonly showQuestion!: Boolean;

  @Prop({ default: true })
  readonly showSequence!: Boolean;

  @Prop({ default: true })
  readonly showInfo!: Boolean;

  @Prop({ default: true })
  readonly showChoices!: Boolean;

  @Prop({ default: false })
  readonly hideCorrect!: Boolean;

  @Prop({ default: false })
  readonly showAllChoices!: Boolean;

  questionTypes = defaultQuestionTypes;
}
