














































































import Firework from 'vue-firework';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Quiz, Game } from '../../models';
import { formatPercent, formatValue } from '@/utils';
import QuizScreen from './QuizScreen.vue';

@Component({ components: { QuizScreen, Firework } })
export default class QuizRanking extends Vue {
  @Prop({ required: true })
  readonly quiz!: Quiz;

  @Prop({ required: true })
  readonly game!: Game;

  timerId: number | undefined = undefined;
  playerIndex = 0;
  formatPercent = formatPercent;
  formatValue = formatValue;

  get user() {
    return this.$store.getters['user/user'];
  }
  get userId() {
    return this.$store.getters['user/userId'];
  }
  get isOwner(): boolean {
    return this.game ? this.userId === this.game.owner : false;
  }
  get orderedRanking() {
    //return this.game.ranking.sort((a, b) => b.points - a.points);
    return Object.values(this.game.stats).sort((a, b) => b.points - a.points);
  }

  created() {
    this.playerIndex = Math.min(4, Object.values(this.game.stats).length);
    this.showRank();
  }

  getRankColor(pidx: number) {
    let color;
    switch (pidx) {
      case 0:
        color = 'orange accent-1';
        break;
      case 1:
        color = 'blue-grey lighten-4';
        break;
      case 2:
        color = 'brown lighten-3';
        break;
      default:
        //color = 'accent lighten-1';
        color = 'transparent';
    }
    return color;
  }

  showRank() {
    this.playerIndex--;
    if (this.playerIndex === 0) {
      clearTimeout(this.timerId);
      this.timerId = undefined;
      this.$confetti.start({
        particles: [{ type: 'circle' }, { type: 'rect' }, { type: 'heart' }],
        defaultSize: 8,
        windSpeedMax: 1,
      });
      return;
    }
    this.timerId = setTimeout(this.showRank, 1500);
  }

  beforeDestroy() {
    clearInterval(this.timerId);
    this.timerId = undefined;
    this.$confetti.stop();
  }
}
