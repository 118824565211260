


























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Quiz, QuizTheme, Game, Participants, User } from '../../models';
import { defaultThemes } from '../../defaults';
import QuizScreen from './QuizScreen.vue';
import GameSettings from './GameSettings.vue';
import GameToolbar from './GameToolbar.vue';

@Component({ components: { QuizScreen, GameSettings, GameToolbar } })
export default class AdminQuizEnd extends Vue {
  @Prop({ required: true })
  readonly quiz!: Quiz;

  @Prop({ required: true })
  readonly game!: Game;

  @Prop({ required: true })
  readonly participants!: Participants;

  showSettingsDialog = false;

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get isOwner(): boolean {
    return this.game ? this.userId === this.game.owner : false;
  }
  get theme(): QuizTheme {
    return defaultThemes[this.quiz.theme];
  }
}
