





















import { Vue, Component } from 'vue-property-decorator';
import { User } from '@/models';
import QuizzesList from '../components/quiz/QuizzesList.vue';

@Component({ components: { QuizzesList } })
export default class QuizDashboardView extends Vue {
  get user(): User {
    return this.$store.getters['user/user'];
  }

  mounted() {
    this.$bus.$emit('title-change', 'Quiz', '/quiz');
  }
}
