


























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Quiz, Game, Participants, User } from '../../models';
import QuizScreen from './QuizScreen.vue';
import GameSettings from './GameSettings.vue';

@Component({ components: { QuizScreen, GameSettings } })
export default class QuizLobby extends Vue {
  @Prop({ required: true })
  readonly quiz!: Quiz;

  @Prop({ required: true })
  readonly game!: Game;

  @Prop({ required: true })
  readonly participants!: Participants;

  showSettingsDialog = false;

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get isOwner(): boolean {
    return this.game ? this.userId === this.game.owner : false;
  }
}
