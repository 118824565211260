





















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class GameStatus extends Vue {
  @Prop({ default: false })
  readonly showTimer!: Boolean;

  @Prop({ default: false })
  readonly lastQuestion!: Boolean;

  @Prop({ default: null })
  readonly timerValue!: Number;

  @Prop({ default: null })
  readonly remainingTime!: Number;

  get timerColor(): string {
    let color = 'green';
    if (this.timerValue <= 10) {
      color = 'red';
    } else if (this.timerValue <= 33) {
      color = 'amber';
    } else {
      color = 'green';
    }
    return color;
  }
}
