






import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { formatDuration } from '@/utils';

@Component
export default class Timer extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly value!: number; // initial time in seconds

  @Prop({ type: Boolean, default: false })
  readonly reverse!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly autostart!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  initialTime: number = 0;
  finalTime: number = 0;
  currentTime: number = 0;
  started = this.$props.autostart;
  timerId: number | null = null;

  get time(): string | null {
    return formatDuration(this.currentTime, 'seconds', 'mm:ss');
  }

  @Watch('value', { immediate: true })
  onValueChange(value) {
    //console.log('Timer value change', value);
    this.reset();
    if (this.started) this.start();
  }

  toggle() {
    this.started = !this.started;
    if (this.started && !this.timerId) this.start();
    this.$bus.$emit('quiz-timer-toggle', this.started);
    //console.log('Timer toggle', this.started);
  }
  tick() {
    if (!this.started) return;
    //console.log('Timer tick', this.currentTime);
    this.reverse ? this.currentTime-- : this.currentTime++;
    this.$bus.$emit('quiz-timer-tick', this.currentTime);
    if (this.timeout()) {
      this.stop();
      this.$bus.$emit('quiz-timer-timeout', this.currentTime);
    }
  }
  start() {
    //console.log('Timer started', value);
    this.reset();
    this.stop();
    this.timerId = setInterval(this.tick, 1000);
    this.$bus.$emit('quiz-timer-start', this.currentTime);
  }
  stop() {
    //console.log('Timer stopped');
    if (!this.timerId) return;
    clearInterval(this.timerId);
    this.timerId = null;
    this.$bus.$emit('quiz-timer-stop', this.currentTime);
  }
  reset() {
    //console.log('Timer reset', value);
    if (this.reverse) {
      this.currentTime = this.initialTime = this.value;
    } else {
      this.currentTime = 0;
      this.finalTime = this.value;
    }
    this.$bus.$emit('quiz-timer-reset', this.currentTime);
  }
  timeout() {
    return this.reverse ? this.currentTime <= 0 : this.currentTime >= this.finalTime;
  }

  beforeDestroy() {
    //console.log('Timer destroyed');
    if (!this.timerId) return;
    clearInterval(this.timerId);
    this.timerId = null;
  }

  onToggle() {
    if (this.readonly) return;
    this.toggle();
  }
  onReset() {
    if (this.readonly) return;
    this.reset();
  }
}
